import api from './api';
import {UserLoginForm} from "../views/Login/Login";
import {ChangePasswordForm} from "../views/Login/ChangePassword";
import {IGetUser} from "../ts/interfaces/interface";
import {IGetOrdersParams} from "../ts/interfaces/Orders/interface";
import {IGetProductsParams} from "../ts/interfaces/Products/interface";

// @ts-ignore
export const serialize = (obj) => {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

// @ts-ignore
export const serializeArray = (obj) => {
    let str = [];
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            if (Array.isArray(obj[p])) {
                for (let o in obj[p]) {
                    str.push(encodeURIComponent(p) + "[]=" + encodeURIComponent(obj[p][o]));
                }
            } else {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
    }
    return str.join("&");
}

//User
export const login = (data: UserLoginForm) => Promise.resolve(api.post('/v1/auth/login', data));
export const rememberPassword = (data: {
    email: string,
    token: string | null
}) => Promise.resolve(api.post(`/v1/auth/remember-password`, data));
export const changePassword = (data: ChangePasswordForm) => Promise.resolve(api.put(`/v1/auth/user/password`, data));
export const getUser = (): Promise<IGetUser> => (api.get(`/v1/auth/user/me`));

//Products
export const getProducts = (data: IGetProductsParams) => (api.get("/v1/products/get-all", {params: data}));

//Orders
export const getOrders = (data: IGetOrdersParams) => (api.get("/v1/orders/get-all", {params: data}));

// Orders Events
export const getOrderEvents = async (id: string | number) => {
    const response = await api.get(`/v1/orders/events/${id}`);
    return response.data;
};
