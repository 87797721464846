import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import styles from "./styles.module.scss";
import BarLoader from "react-spinners/BarLoader";
import {getOrderEvents} from "../../../services/services";
import logoAnymarket from "../../../assets/icons/logo-anymarket.png";
import logoOmie from "../../../assets/icons/logo-omie.png";
import logoEduqi from "../../../assets/icons/logo-eduqi.png";
import iconError from "../../../assets/icons/error.svg";

const OrderTimeline: React.FC = () => {
    document.title = 'Market Eduqi | Linha do Tempo';

    const {id} = useParams<{ id: string }>();

    const {data: timelineData, isLoading, error} = useQuery(['orderEvents', id], () => getOrderEvents(id!), {
        enabled: !!id,
    });

    if (error) {
        return <div className={styles.error}>Erro ao carregar os eventos.</div>;
    }

    const getImageByType = (type: string): string | undefined => {
        switch (type) {
            case 'ANY_MARKET':
                return logoAnymarket;
            case 'OMIE':
                return logoOmie;
            case 'EDUQI':
                return logoEduqi;
            case 'EXPEDICAO':
                return logoEduqi;
            default:
                return undefined;
        }
    };

    return (
        <div className={styles.container}>
            <h1>Linha do Tempo do Pedido: {id}</h1>
            <div className={styles.searchProducts}>
                {isLoading ?
                    <div className={styles.loading}>
                        <BarLoader color="#510071"/>
                        <p>Carregando timeline...</p>
                    </div>
                    :
                    <>
                        {timelineData && timelineData.length > 0 ?
                            <div className={styles.timeline}>
                                {timelineData.map((event: any, index: number) => (
                                    <div
                                        key={index}
                                        className={`${styles.timelineItem} ${event.external ? styles.right : styles.left} ${event.error ? styles.error : ''}`}
                                    >
                                        <div className={styles.content}>
                                            <div className={styles.groupTitle}>
                                                <h3>{event.title}</h3>
                                                {getImageByType(event.type) && (
                                                    <span
                                                        className={`${styles.systemImage} ${event.error ? styles.error : ''}`}>
                                                <img
                                                    src={getImageByType(event.type)}
                                                    alt={event.type}
                                                />
                                            </span>
                                                )}
                                            </div>
                                            <span className={styles.date}>{event.date}</span>
                                            <p>{event.description}</p>
                                            {event.error && (
                                                <div className={styles.errorMessage}>
                                                    <p>
                                                        <img className={styles.errorIcon} src={iconError} alt={"Erro"}/>
                                                        Este evento contém erros. Verifique os detalhes!
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <div className={styles.loading}>
                                <p>Nenhum evento encontrado.</p>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default OrderTimeline;
