import React from 'react';
import {Route, Routes as Router} from 'react-router-dom';
import Login from "../views/Login/Login";
import RequireAuth from "./RequireAuth";
import ChangePassword from "../views/Login/ChangePassword";
import RememberPassword from "../views/Login/RememberPassword";
import NotFound from "../views/NotFound/NotFound";
import Main from "../containers/Main";
import Dashboard from "../views/Dashboard/Dashboard";
import Products from "../views/Products/Products";
import Orders from "../views/Orders/Orders";
import OrderTimeline from "../views/Orders/OrderTimeline/OrderTimeline";

export default function AppRouter() {
    return (
        <Router>
            <Route path={'/login'} element={<Login/>}/>
            <Route path={'/recuperar-senha'} element={<RememberPassword/>}/>
            <Route path={'/alterar-senha'} element={<ChangePassword/>}/>
            <Route
                element={(
                    <RequireAuth>
                        <Main/>
                    </RequireAuth>
                )}>
                <Route path={'/'} element={<Dashboard/>}/>
                <Route path={'/products'} element={<Products/>}/>
                <Route path={'/orders'} element={<Orders/>}/>
                <Route path={'/orders/timeline/:id'} element={<OrderTimeline />} />
            </Route>
            <Route path="*" element={<NotFound/>}/>
        </Router>
    );
};
